html {
	height: 100%;
	overflow-x: hidden;
}

/* menu items */

#menu-bar {
	li {
		list-style: none;
	}
}

#applicants_statuses {
	display: block;
	max-height: 40vh;
	overflow-y: auto;
}

#main-menu {

}

button#closeEvent {
	top: 0.5rem;
  left: 11rem;
  position: fixed;
  color: get-color(secondary);
  cursor: pointer;
  font-size: 1.5rem !important;
  z-index: 10001;

	a {
		color: get-color(secondary);
	}
}

button#hideMenu {
	position: fixed;
  left: 11rem;
  z-index: 1002;
  bottom: 0.5rem;
  display: -ms-flexbox;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 2rem;
  height: 2rem;
  top: unset;
}

#main-menu {
	background-color: $blue;
	z-index: 1000;
  position: fixed;
  height: 100vh;
	background-image: url('/View/assets/img/ama-white.svg');
  background-repeat: no-repeat;
  background-position: left 1rem bottom 1rem;
  background-size: 4rem;
	font-size: 14px;
	font-weight: 400;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;

	> li, li a:not(.statusColorText), li a.sc0 {
		color: $white;
		background: none;
	}

	a.is-active {
		background-color: rgba(255,255,255,.16) !important;
	}

	i {
		padding-right: 0.5rem;
	}

	i.fas.fa-users {
	    padding-right: 0.25rem !important;
	}

	li:hover {
		background-color: rgba(255,255,255,.16);

		a {

		}
	}

	> li {

		ul {
			li {
				padding-left: 0 !important;

				a {
					padding-left: 2rem;
				}
			}
		}

		> a {


		}

	}

	.is-dropdown-submenu {
		background-color: $blue;
	}

}



/* menu items END */

.position-fixed {
	position: fixed;
}

.position-fixed-medium-up {
	position: fixed;
}

.width-max-content {
	width: max-content !important;
}

.width-100vw {
	width: 100vw !important;
}

.text-overflow-ellipsis {
	text-overflow: ellipsis;
}

.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.justify-content-center {
	display: flex;
	justify-content: center;
}

.pointer {
	cursor: pointer !important;
}

.help {
	cursor: help;
}

.crosshair {
	cursor: crosshair;
}

.white {
	background-color: $white !important;
	color: $black !important;
}

.tltip .tooltiptext {
  visibility: hidden;
  background-color: $black;
  color: $white;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  line-height: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

	i {
		color: $white;
	}
}

.tooltiptextClick {
	visibility: visible;
	background-color: $black;
  color: $white;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  line-height: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

	i {
		color: $white;
	}
}

.label.quintary {
	color: $white !important;
}

.tltip:hover .tooltiptext {
  visibility: visible;
}

.bg-primary {
	background-color: $primary-color !important;
}

.bg-secondary {
	background-color: get-color(secondary) !important;
}

.bg-tertiary {
	background-color: get-color(tertiary) !important;
}

.bg-quarternary {
	background-color: get-color(quarternary) !important;
}

.bg-quintary {
	background-color: get-color(quintary) !important;
}

.bg-success {
	background-color: get-color(success) !important;
}

.bg-warning {
	background-color: get-color(warning) !important;
}

.bg-alert {
	background-color: get-color(alert) !important;
}

.bg-black {
	background-color: black !important;
}

.bg-ghostwhite {
	background-color: $ghostwhite !important;
}

.bg-lightblue {
	background-color: $lightblue !important;
}

.bg-lightgray {
	background-color: $light-gray !important;
}

.bg-mediumgray {
	background-color: $medium-gray !important;
}

.bg-darkgray {
	background-color: $dark-gray !important;
}

.text-primary {
	color: $primary-color !important;
}

.text-secondary {
	color: get-color(secondary) !important;
}

.text-success {
	color: get-color(success) !important;
}

.text-warning {
	color: get-color(warning) !important;
}

.text-alert {
	color: get-color(alert) !important;
}

@keyframes rotate {
    0.0%{
    }
    35%{
        transform: rotate(30deg);
    }
    70%{
        transform: rotate(-30deg);
    }
}

#assignDonorID {
	img {
		max-width: 5rem;
	}
}

.view-references-container {

	div.small-12 > label:first-child {
	  font-weight: bold;
	}

}

#spinner {
	display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
	align-items: center;
	justify-content: center;
  position: fixed;
  z-index: 2005;
  padding: 25% 25%;
  background-color: $lightblue;
	opacity: 0.8;

	img {
		max-width: 20rem;
	}
}

#notifications, #checkinCountDiv {
	position: fixed;
	z-index: 11002;
	top: 1rem;
	right: 1rem;
	width: 20rem;
	max-width: 50rem;
}

#checkinCountDiv {
	top: 4rem;
	left: 0;
	width: 100%;
}

.attendeeTime {
	display: grid;
	width: 100%;
}

#topbar_event {
    background-color: $lightblue;
    text-align: center;
		color: $light-gray;
}

#saveEventButton {
	position: fixed;
	bottom: 6rem;
	right: 12rem;
}

#content-container {
	padding-bottom: 10rem;
}

.overflow-hidden {
	overflow: hidden;
}

.show {
	display: block !important;
}

.visible {
	visibility: visible !important;
}

.nowrap {
	white-space: nowrap;
}

.padding-right-1 {
	padding-right: 1rem;
}

.padding-left-1 {
	padding-left: 1rem;
}

.padding-top-0-5 {
	padding-top: 0.5rem;
}

.padding-top-1 {
	padding-top: 1rem;
}

.padding-top-1-5 {
	padding-top: 1.5rem;
}

.padding-bottom-1 {
	padding-bottom: 1rem;
}

.padding-bottom-2 {
	padding-bottom: 2rem !important;
}

.padding-2 {
	padding: 2rem !important;
}

.padding-0-5 {
	padding: 0.5rem;
}

.no-padding, .padding-0 {
	padding: 0 !important;
}

.no-padding-bottom, .padding-bottom-0 {
	padding-bottom: 0 !important;
}

.no-margin-top, .margin-top-0 {
	margin-top: 0 !important;
}

.no-margin-bottom, .margin-bottom-0 {
	margin-bottom: 0 !important;
}

.no-margin-left, .margin-left-0 {
	margin-left: 0 !important;
}

.margin-top-bottom-1 {
	margin: 1rem 0 1rem 0 !important;
}

.margin-bottom-1 {
	margin-bottom: 1rem !important;
}

.margin-bottom-2 {
	margin-bottom: 2rem !important;
}

.margin-top-bottom-0-25 {
	margin: 0.25rem 0 0.25rem 0 !important;
}

.width-3 {
	width: 3rem !important;
}

.width-4 {
	width: 4rem !important;
}

.width-5 {
	width: 5rem !important;
}

.line-height-normal {
	line-height: normal !important;
}

.line-height-1-5 {
	line-height: 1.5 !important;
}

.wysiwyg-text-align-left {
  text-align: left;
}

.wysiwyg-text-align-center {
  text-align: center;
}

.wysiwyg-text-align-right {
  text-align: right;
}

.header {
	+ .applicant_row, + .event_row, + .attendee_row, + .user_row, + .assignment_row, + .standard_row {
    border-top: none !important;
	}

	div:first-child {
		padding-left: 0.5rem;
	}

	select {
		border: 0;
    padding-top: 0;
    padding-left: 0;
    margin: 0;
    height: auto;
	}

	select:focus {
		border: 0;
		box-shadow: none;
	}
}

.applicant_row, .event_row, .attendee_row, .user_row, .assignment_row, .standard_row {
	border-top: 1px solid get-color(tertiary);

	&:hover {
		background: get-color(tertiary);
		border-radius: $global-radius;
		border-top: 1px solid transparent;
	}

	&:hover + .applicant_row, &:hover + .event_row, &:hover + .attendee_row, &:hover + .user_row, &:hover + .assignment_row, &:hover + .standard_row {
		border-top: 1px solid transparent;
	}

	&:hover a {
		color: $anchor-color-hover;
	}

	div:hover {
		overflow: visible;
	}

	div:first-child {
		padding-left: 0.5rem;
	}

	div:last-child {
		padding-right: 0.5rem;
	}

	a {
		display: inline-block;
		padding-top: 1rem;
		padding-bottom: 1rem;
		width: 100%;
	}
	a:link, a:visited, a:focus {
		color: $black;
	}

	div.edit, div[id$='_donorID'] {
		&:hover {
			background: $warning-color;
			a {
				color: white;
			}
		}
	}

}


.notification_row {

	div:first-child, div:last-child {
		padding-left: 0;
 		padding-right: 0;
	}


	.cell {
		a {
			color: $black;
		}
	}
}

.orbit-slide, .orbit-container {
	outline: none !important;
}

.orbit-form-button {
	background-color: $primary-color;
	cursor: pointer;
}

.no-border {
	border: none !important;
}

.profilepic {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;
}
/*
.orbit-previous {
	left: 15% !important;
	top: 80% !important;
}

.orbit-next {
	right: 15% !important;
	top: 80% !important;
}
*/
.orbit-container {
	overflow-y: auto !important;
}
/*
.full-height {
	height: 100vh !important;
}*/

.switch-paddle-wide-5 {
	width: 5rem !important;
}

.switch-paddle-wide-7 {
	width: 7rem !important;
}

.switch-paddle-wide-10 {
	width: 10rem !important;
	height: 3rem !important;
}

.color-black {
	color: black !important;
}

.color-lightgray {
	color: $light-gray !important;
}

.color-mediumgray {
	color: $medium-gray !important;
}

.color-darkgray {
	color: $dark-gray !important;
}

input:checked ~ .switch-paddle-wide-7::after {
    left: 5.25rem !important;
}

input:checked ~ .switch-paddle-wide-5::after {
    left: 3.25rem !important;
}

input.hiddenText {
	width: 0px;
  padding: 0;
	border-width: 0px !important;
  position: absolute;
}

.slider-container {
	position: relative;
	width: 100%;
	max-width: 600px;
	margin: auto;
}

.slider-handle {
	display: flex !important;
	justify-content: center;
	color: $white;
}

.slider {
	margin-top: 1rem !important;
  margin-bottom: 5rem !important;
}

.slider-label {
	font-size: 0.9rem;
	// flex: 1; /* Distribute space equally */
  text-align: center; /* Center the text within each label */

}

.slider-labels {
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 100%;
	width: 100%;
	transform: translateY(.5rem);

	:first-child {
		text-align: left;
	}
	:last-child {
		text-align: right;
	}
}

.slider-labels-ends {
	@extend .slider-labels;
	top: 0px;
	position: static;
}

.checkbox, .radio {
	appearance: none;
	visibility: hidden;
}
.checkbox + label, .radio + label {
	position: relative;
	display: block;
	cursor: pointer;
	margin: 0 .9rem;
	font-family: 'Roboto', sans-serif;
	z-index: 1;
	overflow: hidden;
	transition: .2s ease;
	display: flex;
	align-items: center;

	.dot {
		position:relative;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border: 2px solid $dark-gray;
		border-radius: 50%;
		margin-right: .5rem;
    vertical-align: text-top;
		line-height: 40px;
	}

	//noinspection CssNoGenericFontName
	.dot::before {
		content: "\f111";
		position: absolute;
    // top: -71%;
    // left: 37%;
		transform: translate(-50%, -50%) scale(0);
		// width: 11px;
		// height: 11px;
		color: $primary-color;
		border-radius: 50%;
		// transition: .3s ease;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
	}

}
input[type="checkbox"] + label {
	> .dot {
		border-radius: 25%;
	}

	.dot::before {
		content: "\f00c";
		// top: -69%;
    // left: 39%;
	}
}
input:checked + label {
	.dot {
		border-color: $primary-color;
	}
	.dot::before {
		transform: translate(-7%, -34%) scale(1);
	  transform-origin: 50%;
	}
}
input[type="checkbox"]:checked + label .dot::before {
	transform: translate(-10%, -33% )scale(1);
}
input:checked + label .dot {
	color: $primary-color;
}

label.required::after {
  content: " *";
  color: red;
}

#createInvoiceBox {
  width: max-content;
}

// multiselect-dropdown
.multiselect-dropdown{
  display: inline-block;
  padding: 2px 5px 0 5px;
  border-radius: 4px;
  border: solid 1px #000;
  background-color: white;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
	margin-bottom: 1rem;
  width: 100% !important;
}
.multiselect-dropdown span.optext, .multiselect-dropdown span.placeholder{
  margin-right:0.5em;
  margin-bottom:2px;
  padding:1px 0;
  border-radius: 4px;
  display:inline-block;
}
.multiselect-dropdown span.optext{
  background-color:lightgray;
  padding:1px 0.75em;
}
.multiselect-dropdown span.optext .optdel {
  float: right;
  margin: 0 -6px 1px 5px;
  font-size: 0.7em;
  margin-top: 2px;
  cursor: pointer;
  color: #666;
}
.multiselect-dropdown span.optext .optdel:hover { color: #c66;}
.multiselect-dropdown span.placeholder{
  color:#ced4da;
}
.multiselect-dropdown-list-wrapper{
  box-shadow: gray 0 3px 8px;
  z-index: 100;
  padding:2px;
  border-radius: 4px;
  border: solid 1px $black;
  display: none;
  margin: -1px;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  background: white;
  width: max-content;
}
.multiselect-dropdown-list-wrapper .multiselect-dropdown-search{
  margin-bottom:5px;
}
.multiselect-dropdown-list{
  padding:2px;
  height: 15rem;
  overflow-y:auto;
  overflow-x: hidden;
}
.multiselect-dropdown-list::-webkit-scrollbar {
  width: 6px;
}
.multiselect-dropdown-list::-webkit-scrollbar-thumb {
  background-color: #bec4ca;
  border-radius:3px;
}

.multiselect-dropdown-list div{
  padding: 5px;
}
.multiselect-dropdown-list input{
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.35em;
}
.multiselect-dropdown-list div.checked{
}
.multiselect-dropdown-list div:hover{
  background-color: #ced4da;
}
.multiselect-dropdown span.maxselected {width:100%;}
.multiselect-dropdown-all-selector {border-bottom:solid 1px #999;}

.disabled-click {
	pointer-events:none;
}
// multiselect-dropdown END

.padding-hon {
	position: absolute !important;
	width: 1px !important;
	overflow: hidden !important;
	left: -200rem !important;
	top: -200rem !important;
}

// upload form

.upload p.filedrop, .uploadinline p.filedrop{
  // width: 100% !important;
  // height: 100% !important;
	display: flex;
	align-items: center;
  text-align: center !important;
  height: 9rem !important;
	width: 20rem !important;
	max-width: 70vw !important;
  color: black !important;
  cursor: pointer;
	border: 4px dashed #333 !important;
	margin-bottom: 0px;
}
.upload .file, .uploadinline .file {
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 20rem !important;
  max-width: 70vw !important;
  height: 10rem !important;
	cursor: pointer;
  outline: none !important;
  opacity: 0 !important;
}

.uploadinline .file {
	width: 100% !important;
}
.uploadinline p.filedrop {
	width: 100% !important;
	height: 10rem !important;
	display: flex;
	align-items: center;
	line-height: initial !important;
}

.filedrop_button {
	height: 2rem;
	width: 8rem;
	cursor: pointer;
}

.filedrop_button_icon {
	height: 2rem;
	width: 2rem;
	cursor: pointer;
}

.upload .file_button, .uploadinline .file_button {
	height: 2rem !important;
	width: 8rem !important;
}
// upload form END

//

// .custom-combobox {
//     position: relative;
//     display: inline-block;
//   }
//   .custom-combobox-toggle {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     margin-left: -1px;
//     padding: 0;
//   }
//   .custom-combobox-input {
//     margin: 0;
//     padding: 5px 10px;
//   }

input#select {
	text-align: left;
}


.chips-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  cursor: text;
  background-color: #f9f9f9;
}

.chip {
  background-color: #e0e0e0;
  color: #333;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.chip .remove-btn {
  background: none;
  border: none;
  color: #888;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
  line-height: 1;
}

.chip .remove-btn:hover {
  color: #d32f2f;
}

.texta {
	height: 1rem;
  border: none;
  background-color: $ghostwhite;
  box-shadow: none;
  resize: none;
}

div.delete {
	padding: 1rem 0;

	.switch {
		margin: 0;
	}
}

.absolute {
	position: absolute !important;
	z-index: 10001;
}

#commentsBox, .min-width60percent {
	min-width: 60%;
}

.permissionsBox {
	background-color: $white;
	border: 1px solid $dark-gray;
  position: absolute;
  margin-top: 12rem;
	padding: 1rem;
	padding-bottom: 10rem;
	z-index: 1000;
}

body > div.callout {
	margin-left: 14rem !important;
}

.callout.primary {
	background-color: $primary-color !important;
}
.callout.secondary {
	background-color: get-color(secondary) !important;
}
.callout.success {
	background-color: get-color(success) !important;
}
.callout.warning {
	background-color: get-color(warning) !important;
}
.callout.alert {
	background-color: get-color(alert) !important;

	a {
    color: black !important;
	}
}

.callout-card, .callout-card-full {
	@extend .callout;
	box-shadow: $main-shadow;
	border-color: transparent !important;
	border-radius: 4px !important;
	z-index: initial !important;
	position: initial !important;
}

.callout-card-full.no-padding {
	table {
		thead, tbody {
			border-width: 0px;
		}
	}
}

.profile-card {
	height: 100%;

	> img {
    margin: 1rem;
	}
}

.delete-group {
	@extend .input-group;
	width: max-content !important;
  padding: 1rem 1rem 0rem 1rem;
  background-color: $dark-gray;
	border-radius: 4px;

	:first-child, :first-child.input-group-button > * {
    border-radius: 4px !important;
	}

	.button {
		margin-right: .5rem !important;
	}
}

.table-scroll table {
	width: 100% !important;

	thead td {
    text-wrap: nowrap;
	}
}

.block-label {
	@extend .label;
	display: inline-block;
	white-space: pre-line !important;
	font-size: inherit !important;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.inline {
	display: inline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-baseline {
	vertical-align: baseline !important;
}

.inline-block {
	display: inline-block !important;
}

.role-1 {
	background-color: get-color(success) !important;
}

.role-2 {
	background-color: get-color(primary) !important;
}

.role-3 {
	background-color: get-color(secondary) !important;
}


.grouptype_elective {
	background-color: $dark-gray !important;
}

.statusDiv {
	overflow-y: hidden;
	height:100%;
	max-height:3rem;
}

.statusDivExpand {
	max-height: fit-content;
}

// status chooseable colors
$statusColor1: #C6C4C4;
$statusColor2: #EF6B6A;
$statusColor3: #EC8D71;
$statusColor4: #F1BD6C;
$statusColor5: #F7DF72;
$statusColor6: #B3DF96;
$statusColor7: #84C8A9;
$statusColor8: #4ECBC4;
$statusColor9: #4573D2;
$statusColor10: #CC95EA;
$statusColor11: #F9A9EF;
$statusColor12: #6C6E6F;

.statusColor.sc0, .statusColorText.sc0 {
	background-color: initial;
	color: initial;
}

@for $i from 1 through 12 {
  .statusColor.sc#{$i} {
    background-color: nth($statusColor1 $statusColor2 $statusColor3 $statusColor4 $statusColor5 $statusColor6 $statusColor7 $statusColor8 $statusColor9 $statusColor10 $statusColor11 $statusColor12, $i);
    color: if(lightness(nth($statusColor1 $statusColor2 $statusColor3 $statusColor4 $statusColor5 $statusColor6 $statusColor7 $statusColor8 $statusColor9 $statusColor10 $statusColor11 $statusColor12, $i)) > 70%, #000, #fff);
  }
  .statusColorText.sc#{$i} {
    color: nth($statusColor1 $statusColor2 $statusColor3 $statusColor4 $statusColor5 $statusColor6 $statusColor7 $statusColor8 $statusColor9 $statusColor10 $statusColor11 $statusColor12, $i) !important;
  }
}

.colorPickerLabel {
    line-height: 1rem;
    margin-left: 0.1rem;
    vertical-align: text-top;
    border-radius: 50%;
    border: 1px solid lightgray;
}
// status chooseable colors

.historyBox, #commentsBox,.commentsBox {
	width: 40rem !important;
	max-width: 80vw !important;
  max-height: 90vh;
  overflow-y: auto;
}

#interviewsBox {
  max-height: 90vh;
  overflow-y: auto;
}

.historyBox {
	color: #767676;
  font-size: 75% !important;
  line-height: 1.5rem;
}

.ama.badge {
  background: none;
  margin-right: 0.3rem;
}

.ama.badge::before, .ama.badge::after {
  content: "a";
  color: black;
  position: absolute;
  margin-left: -0.45rem;
  font-size: 120%;
  margin-top: -0.05rem;
}

.ama.badge::after {
  margin-left: 0.05rem;
}

a.faq-link::before {
	content: "\f059";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

.display-inline {
	display: inline !important;
}

.display-inline-block {
	display: inline-block !important;
}

.code {
	background-color: get-color(secondary);
	padding: 1rem;
	font-size: 70%;
}

.checkinAttendeeButton, .checkoutAttendeeButton {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;

	i {
		font-size: 1.5rem;
	}
}


.more-menu {
	height: 60vh;
	overflow-y: scroll;
	border-top: 1px solid get-color(primary);
}

input.pickdate {
    width: initial;
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-block;
}

.flex-v-center {
	display: flex;
	align-items: center;
}

.font-bold {
	font-weight: bold !important;
}

.accordion-content {
	background-color: get-color('tertiary') !important;
}


// font awesome

@each $name,$color in $foundation-palette {
  .fas.#{$name},.fa-regular.#{$name},.fa-solid.#{$name} {
    color: $color;
  }
}

#main-menu .fai {
	padding-left: 3.5rem !important;
}

//noinspection CssNoGenericFontName
.fai::before {
	font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  margin-left: -2rem;
  width: 2rem;
  text-align: center;
}

@each $name,$unicode in $fa-icons {
	.fai-#{$name}::before {
	  content: #{'"\\' + $unicode + '"'};
	}
}
// font awesome  END

// jqte editor for emails
.jqte {
	ul, ul > li {
		list-style-type: disc !important;
	}
	ol, ol > li {
		list-style-type: decimal !important;
	}

}
// jqte editor for emails END

// otp styling
.otp-input-wrapper {
  text-align: center;
  display: inline-block;
  padding: 2rem 0rem 2rem 2rem;
}
.otp-input-wrapper input {
	padding: 0;
	width: 18rem;
	font-size: 2.5em;
	font-weight: 600;
	color: #3e3e3e;
	background-color: transparent;
	border: 0;
	margin-left: 2px;
	letter-spacing: 1.45rem;
	font-family: sans-serif !important;
	box-shadow: none;
	-webkit-appearance: none;
  margin: 0;
	-moz-appearance: textfield;
}
.otp-input-wrapper input:focus {
  box-shadow: none;
  outline: none;
	border: 0;
	-webkit-appearance: none;
  margin: 0;
	-moz-appearance: textfield;
}
.otp-input-wrapper svg {
  position: relative;
  display: block;
  width: 16rem;
  height: 2px;
}
/* Chrome, Safari, Edge, Opera */
.otp-input-wrapper input::-webkit-outer-spin-button,
.otp-input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp-input-wrapper input[type=number] {
  -moz-appearance: textfield;
}
// otp styling END
