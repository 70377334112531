@include breakpoint(small only) {
  #topbar_event {
    background-color: $primary-color;
    bottom: 0;
    position: fixed;
    z-index: 2002;
    width: 100vw;
    padding: 0.7rem;
    background-image: url('/View/assets/img/ama-white.svg');
    background-repeat: no-repeat;
    background-position: left 0.7rem bottom 0.5rem;
    background-size: 2rem;
  }

  #main-menu {
    position: fixed !important;
    bottom: 0 !important;
    width: 100vw !important;
    height: 6.9rem !important;
    justify-content: space-between;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-image: url('/View/assets/img/ama-white.svg');
    background-repeat: no-repeat;
    background-position: left 0.7rem bottom 0.5rem;
    background-size: 2rem;

    > li a.is-active {
   		color: $primary-color !important;
      background-color: rgba(0,0,0,0) !important;
   	}

    li:hover {
  		background-color: rgba(0,0,0,0) !important;

  		a {

  		}
  	}

    > li {

      ul {
  			li {

  				a {
  					padding-left: 1rem;
  				}
  			}
  		}

  		> a {
  			text-align: center;
        font-size: 80%;
        color: $medium-gray;
        padding-left: 0 !important;
        padding-right: 0 !important;
        // margin: 0px !important;

  			> i {
  				font-size: 1.5rem;
  				padding-bottom: 0.5rem;
          padding-right: 0;
  				display: block;
  			}
  		}

  		> a::after {
  			display: none !important;
  		}
  	}

    .is-dropdown-submenu {
      bottom: 6.9rem !important;
      top: initial !important;
      width: 100vw !important;
      left: 0;
      position: fixed;
      background-color: $blue;
      border: 0;

      a {
        color: $white;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }



  }

  .grid-container {
    padding-bottom: 4rem;
  }

  .grid-container.full {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .historyBox, #commentsBox {
    max-width: 99% !important;
    left: 0 !important;
    width: 90vw !important;
  }

  .display-block-for-small-only {
    display: block !important;
  }


  #main-menu .fai {
    padding-left: 2.5rem !important;
  }

  .margin-bottom-1-small-only {
    margin-bottom: 1rem !important;
  }

  .callout-card-full {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }


  .position-fixed-medium-up {
  	position: inherit;
  }

  .otp-input-wrapper {
    padding-left: 0px !important;
  }



} /* breakpoint small only END */

@include breakpoint(medium up) {

  .padding-right-1-medium {
    padding-right: 1rem;
  }

  #burger-menu {
    display: none;
    right: initial;
    left: 0.5rem;
    position: fixed;
    font-size: 1.5rem;
  }

  #main-menu {
    min-width: 13rem;
    width: 13rem;
    transition: width 1s; /* Smooth expansion */

    > li > ul {
      display: none;
    }

    li ul li {
      padding-left: 1rem;
    }

    #searchfield {
  		display: block;

      li {
        padding-left: 0;
      }
    }

    #searchgroup {
      transition: width 0.5s;
    }

    #searchgroup:focus-within {
      width: 18.3rem;
    }
  }

  #main-menu.fitContent {
    width: fit-content;
  }

  #checkinCountDiv {
    left: 14rem !important;
  }

  #content-container {
    padding-left: 2rem;
    padding-right: 2rem;
    transition: padding-left 1s;
  }

  .content-container-pad-left {
    padding-left: 14rem !important;
  }

  .close-button {
    font-size: 1.5rem !important;
  }


} /* breakpoint medium up */
