@media (prefers-color-scheme: dark) {

  $primary: #5F7ADB;
  $secondary: #DDA01D;
  $tertiary: #A2B2EE;
  $quarternary: #E6F4F1;
  $success: #295a16;
  $warning: #a88c00;
  $alert: #a23a2a;

 $dark: rgb(38,40,40);
 $lightdark: rgb(60,60,60);

 a {
   color: $primary !important;
 }

 body {
  background-color: $dark !important;
  color: #fefefe !important;
 }

 .dropdown-pane, .menu, .menu ul, #sentEmailsBox, .historyBox, #commentsBox, #assignDonorID, #exportForm, .interviewBox, .permissionsBox, .ui-widget-content, thead, thead, tbody, tfoot, .callout, table.unstriped tbody tr, tbody tr:nth-child(even), .accordion, .accordion-content, .tabs, .tabs-content, .card {
  background-color: $lightdark !important;
  color: #fefefe !important;
 }

   @for $i from 1 through 12 {
     .statusColor.sc#{$i} {
       background-color: nth($statusColor1 $statusColor2 $statusColor3 $statusColor4 $statusColor5 $statusColor6 $statusColor7 $statusColor8 $statusColor9 $statusColor10 $statusColor11 $statusColor12, $i);
       color: if(lightness(nth($statusColor1 $statusColor2 $statusColor3 $statusColor4 $statusColor5 $statusColor6 $statusColor7 $statusColor8 $statusColor9 $statusColor10 $statusColor11 $statusColor12, $i)) > 70%, #000, #fff);
     }
     .statusColorText.sc#{$i} {
       color: nth($statusColor1 $statusColor2 $statusColor3 $statusColor4 $statusColor5 $statusColor6 $statusColor7 $statusColor8 $statusColor9 $statusColor10 $statusColor11 $statusColor12, $i) !important;
     }
   }
 .callout.primary {
 	background-color: $primary !important;
 }
 .callout.secondary {
 	background-color: $secondary !important;
 }
 .callout.success {
   background-color: $success !important;
 }
 .callout.warning {
   background-color: $warning !important;
 }
 .callout.alert {
   background-color: $alert !important;

   a {
     color: black !important;
 	 }
}

 .button {
   background-color: $primary !important;
   color: $white !important;
 }
 .button.success {
   background-color: $success !important;
 }
 .button.warning {
   background-color: $warning !important;
 }

 .button.alert {
   background-color: $alert !important;
 }

 .button.primary {
   background-color: $primary !important;
   color: $white !important;
 }

 .button.secondary {
   background-color: $secondary !important;
   color: $black !important;
 }

 .button.tertiary {
   background-color: $tertiary !important;
   color: $black !important;
 }

 .button.quarternary {
   background-color: $quarternary !important;
   color: $black !important;
 }

 .label {
   color: $white !important;
 }
 .label.success {
   background-color: $success !important;
 }
 .label.warning {
   background-color: $warning !important;
 }

 .label.alert {
   background-color: $alert !important;
 }

 .label.primary {
   background-color: $primary !important;
   color: $white !important;
 }

 .label.secondary {
   background-color: $secondary !important;
   color: $black !important;
 }

 .label.tertiary {
   background-color: $tertiary !important;
   color: $black !important;
 }

 .label.quarternary {
   background-color: $quarternary !important;
   color: $black !important;
 }

 label, .ui-widget-content, .callout {
  color: #fefefe !important;
 }

 .close-button {
  color: $secondary !important;

  :hover {
    color: $white !important;
  }
 }

 .callout.alert {
    .close-button {
      color: $black !important;
    }
 }

 textarea, input:not([type="submit"]), select {
  background-color: #333 !important;
  color: #fefefe !important;
 }

 .applicant_row, .event_row, .attendee_row, .assignment_row, .user_row {
   a {
     color: #fefefe !important;
   }
 }

 .applicant_row:hover, .event_row:hover, .attendee_row:hover, .assignment_row:hover, .user_row:hover {
   background-color: $dark-gray;
 }

 #main-menu {
   > li, li a:not(.statusColorText), li a.sc0  {
 		color: $white !important;
 	}
 }

 .ama.badge::before, .ama.badge::after {
   color: #fefefe !important;
 }

 .white {
   background-color: $dark !important;
   color: $white !important;
 }

 .help-text {
   color: #fefefe !important;
 }

 .monthdate, .multiselect-dropdown-list, .multiselect-dropdown {
   background-color: $dark-gray !important;
 }

 .callout-card-full {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: transparent !important;
    box-shadow: none;
 }

  .attendance_container {
	  .all-date .grid-x div, .b-bottom, .b-right {
		  border-color: $black !important;
		}
  }

.otp-input-wrapper input {
  background-color: transparent !important;
}


}
