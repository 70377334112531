.attendance_container {
  // margin: 0 auto;
  // height: 487px;
  // width: 421px;
  // background-color: get-color(primary);


  .curr-month {
    text-align: center;
    padding: 1rem 0;
  }

  .all-days {

  }


  .grid-x div {
    text-align: center;
  }

  .all-date {

  }

  .all-date .grid-x div {
    line-height: 3.7rem;
    border-top: 1px solid $white;
    border-left: 1px solid $white;
    background-color: $light-gray;
  }

  .b-bottom {
    border-bottom: 1px solid $white;
  }

  .b-right {
    border-right: 1px solid $white;
  }

  .all-date .grid-x div.monthdate:hover {
    background-color: get-color(primary);
    color: $white;
  }

  #currYear, #currMonth {
  	visibility: hidden;
  }
  .curDate_today {
    box-shadow: 0 0 0 0.2rem get-color(quarternary) inset;
    font-weight: bold;
    color: $black;
  }
  .dateSelected {
    box-shadow: 0 0 0 0.2rem get-color(success) inset;
    font-weight: bold;
    color: get-color(quarternary);
  }
  .dateUnselected {
    box-shadow: 0 0 0 0.2rem get-color(alert) inset;
    font-weight: bold;
    color: get-color(quarternary);
  }
  .dateException {
    box-shadow: 0 0 0 0.2rem get-color(quintary) inset;
    font-weight: bold;
    color: get-color(quintary);
  }
}

.attendanceStatus_1 {
  background-color: get-color(success) !important;
}
 .attendanceStatus_2 {
  background-color: get-color(warning) !important;
}
 .attendanceStatus_3 {
  background-color: get-color(alert) !important;
  color: $white;
}
 .attendanceStatus_6 {
  background-color: get-color(alert) !important;
  color: $white;
}
 .attendanceStatus_8 {
  background-color: get-color(quintary) !important;
  color: $white;
}
 .attendanceStatus_9 {
  background-color: get-color(quarternary) !important;
  color: $black;
}

.crossed_out {
  	background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent));
    background-image: repeating-linear-gradient(130deg, transparent 0%, transparent 48%, get-color(primary) 50%, transparent 52%, transparent 100%);
}

.holiday {
  background-color: get-color(primary) !important;
}

.startdate, .enddate, .milestone {
  -webkit-box-shadow: inset 0 0 0 .2rem get-color(secondary);
	-moz-box-shadow: inset 0 0 0 .2rem get-color(secondary);
	box-shadow: inset 0 0 0 .2rem get-color(secondary);
}
